import * as React from "react"
import richMan from "../images/rich-man.png"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  fontSize: "18px",
  lineHeight: 1.5
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 30,
  textAlign: "center",
}
const richManStyles = {
  width: "160px",
  height: "160px",
  margin: "0 auto",
  display: "block"
}
const formStyles = {
  background: "#fff",
  clear: "left",
  fontSize: "18px",
  width: "100%",
  marginBottom: "30px"
}
const hiddenInputStyles = {
  position: "absolute",
  left: "-5000px"
}
const emailInputStyles = {
  marginRight: "10px"
}
const labelStyles = {
  fontSize: "24px"
}
const taglineStyles = {
  textAlign: "center"
}
const attributionStyles = {
  fontSize: "9px",
  color: "#ccc",
  textAlign: "center",
  display: "block",
  margin: "10px"
}
const attributionLinkStyles = {
  color: "#ccc",
}
const philosophyListStyles = {
  maxWidth: "800px",
  margin: "0 auto"
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Cosimo Finance</title>

      <h1 style={headingStyles}>
        Hey yo!
      </h1>

      <img alt="Reicher Typ" src={richMan} style={richManStyles}></img>
      <small style={attributionStyles}>Icons made by <a style={attributionLinkStyles} href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">smalllikeart</a> from <a style={attributionLinkStyles} href="https://www.flaticon.com/" title="Flaticon">flaticon.com</a></small>
      <p style={taglineStyles}>Cosimo ist der coolste Weg einen Überblick über deine persönlichen Finanzen zu erhalten.</p>

      <div id="mc_embed_signup" style={formStyles}>
        <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
        <form action="https://finance.us1.list-manage.com/subscribe/post?u=54ad207bc49494748542355c7&amp;id=1de20178f8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
              <label htmlFor="mce-EMAIL" style={labelStyles}>Ja, ich möchte am Beta-Test teilnehmen</label>
              <input style={emailInputStyles} type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Email-Adresse" required />

              <div style={hiddenInputStyles} aria-hidden="true">
                <input type="text" name="b_54ad207bc49494748542355c7_1de20178f8" tabIndex="-1" />
              </div>
              <div className="clear">
                <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="button">Anmelden</button>
              </div>
            </div>
        </form>
      </div>

      <h2 style={headingStyles}>Philosophie</h2>

      <ul style={philosophyListStyles}>
        <li><strong><em>"what gets measured, gets managed":</em></strong> das Messen der Ausgaben hilft Ausgabeverhalten zu verändern</li>
        <li>Keine Budgets oder Ziele, nur eine Metrik: <strong>die Sparrate</strong>. So lange wir jeden Monat pflichtbewusst sparen und investieren, ist es scheißegal wo der Rest der Kohle hin geht. Es gibt keinen Grund jeden einzelnen Cent nachzuverfolgen.</li>
        <li>Vergleich mit "Durchschnittsbürger" hilft bei der Einschätzung des eigenen Ausgabeverhaltens</li>
        <li>Alle Daten verbleiben lokal auf dem eigenen Rechner, keine Cloud, kein Tracking</li>
        <li>Es gibt keine Kontostandsanzeige, Cosimo ist keine Buchhaltungs-Software, es geht nur um eine Ausgabenübersicht</li>
      </ul>
    </main>
  )
}

export default IndexPage
